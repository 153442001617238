<script lang="ts">
    export let tabs: string[] = [];
    export let activeTab: string;
    export let onTabChange: (tab: string) => void;
</script>

<div
    class="mb-6 flex w-full max-w-[800px] justify-center border-b border-gray-300 pt-6 font-sans dark:border-gray-700 md:w-[70%]"
>
    {#each tabs as tab}
        <button
            class="flex-1 border-b-2 py-2 text-center font-bold transition-colors duration-300 dark:text-white

                {activeTab === tab
                ? 'border-[#1C1C1C] text-[#1C1C1C] dark:border-white dark:text-white'
                : 'border-transparent text-gray-500 hover:text-[#1C1C1C] dark:text-gray-400 dark:hover:text-white'}"
            on:click="{() => onTabChange(tab)}"
        >
            {tab}
        </button>
    {/each}
</div>
